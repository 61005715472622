.container{
    width: 100vw;
    height: 100vh;
    background-color: beige;
}

.pointer{
    position: absolute;
    background-color: crimson;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    left:-15px;
    top:-15px;
}
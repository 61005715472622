.orange {
  color: orange;
}

.profile {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  padding: 1rem;
  background-color: #ebebeb;
  border-radius: 20px;
  box-shadow: 7px 5px 23px -9px rgba(0, 0, 0, 0.75);
  margin-bottom: 1rem;
  -webkit-box-shadow: 7px 5px 23px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 5px 23px -9px rgba(0, 0, 0, 0.75);
}

.photo {
  width: 200px;
  height: 200px;
  border-radius: 100%;
}

.avatar {
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;
}

.new {
  position: absolute;
  left: 70%;
  top: 10%;
  background-color: aquamarine;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
}

.container {
  width: 500px;
  margin: auto;
}

.banner {
  text-align: center;
  margin-top: 5rem;
  font-size: 2rem;
  background-color: gold;
  font-weight: bold;
}

.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  padding: 1rem;
  border-radius: 1rem;
  border: 2px solid rgb(215, 127, 12);
}

.counter:hover {
  background-color: rgb(246, 243, 197);
}

.number {
  font-size: 7rem;
}

.button {
  font-size: 2rem;
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  background: rgb(215, 127, 12);
  background: linear-gradient(
    90deg,
    rgba(215, 127, 12, 1) 0%,
    rgba(253, 220, 29, 1) 100%
  );
}

.total {
  font-size: 1rem;
  margin-left: -1.5rem;
}

